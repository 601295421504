
import { defineComponent, reactive, toRefs, onBeforeMount, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from '@/store'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import axios from 'axios'
import { parseTime } from '@/utils/index'
interface Role {
    title: string
    introduce: string
    datetime: string|null
    content: string
    coverimg: string
}
const defaultRole: Role = {
  title: '我的',
  introduce: '描述',
  datetime: '',
  content: '',
  coverimg: ''
}
export default defineComponent({
  components: {
    QuillEditor
  },
  setup() {
    const imageFrontUrl = ref('uploadRef')
    // const uploadRef = ref()
    const state = reactive({
      token: useStore().state.user.token,
      defaultFileList: [],
      dialogVisible: false,
      role: Object.assign({}, defaultRole),
      dialogType: 'new',
      userList: [],
      options: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗斜体下划线删除线
              ['blockquote', 'code-block'], // 引⽤代码块
              [{ list: 'ordered' }, { list: 'bullet' }], // 有序、⽆序列表
              [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              // [{'direction': 'rtl'}],                         // ⽂本⽅向
              [{ size: ['small', false, 'large', 'huge'] }], // 字体⼤⼩
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜⾊、字体背景颜⾊
              [{ font: [] }], // 字体种类
              [{ align: [] }], // 对齐⽅式
              ['clean'], // 清除⽂本格式
              ['link', 'image', 'video'] // 链接、图⽚、视频
            ]
          }
        }
      },
      getAllUserNo: async() => {
        await axios({
          method: 'get',
          url: 'https://gw.bainiankeji.com/news/'
        }).then((res: any) => {
          if (res.data?.code === 0) {
            state.userList = res.data.data.dtxw
            ElMessage({
              type: 'success',
              message: res.data?.msg
            })
          } else {
            ElMessage({
              type: 'error',
              message: res.data?.msg
            })
          }
        })
      },
      deleteManageBtn: async(scope: any) => {
        const id = scope.row.id
        await axios({
          method: 'delete',
          url: `https://gw.bainiankeji.com/news/?id=${id}`
        }).then((res: any) => {
          if (res.data?.code === 0) {
            ElMessage({
              type: 'success',
              message: res.data?.msg
            })
            state.getAllUserNo()
          } else {
            ElMessage({
              type: 'error',
              message: res.data?.msg
            })
          }
        })
      },
      handleCreateRole: () => {
        state.dialogType = 'new'
        console.log(state.role)
        state.dialogVisible = true
      },
      submitUpload: async() => {
        // imageFrontUrl.value?.submit()
        // await axios({
        //   method: 'post',
        //   url: 'https://gw.bainiankeji.com/news/',
        //   data: state.role
        // }).then((res: any) => {
        //   if (res.data?.code === 0) {
        //     ElMessage({
        //       type: 'success',
        //       message: res.data?.msg
        //     })
        //   } else {
        //     ElMessage({
        //       type: 'error',
        //       message: res.data?.msg
        //     })
        //   }
        // })
      },
      dialogclose: () => {
        state.role = {
          title: '',
          introduce: '',
          datetime: '',
          coverimg: '',
          content: ''
        }
        console.log(state.role)
      },
      editnews: async(scope: any) => {
        await axios({
          method: 'get',
          url: `https://gw.bainiankeji.com/news/${scope.id}`
        }).then((res: any) => {
          console.log(res.data.data.dtxw)
          if (res.data?.code === 0) {
            state.role = {
              title: scope.name,
              introduce: scope.abstract,
              datetime: scope.datetime,
              coverimg: scope.coverimg,
              content: res.data.data.dtxw[0].content
            }
            console.log(state.role.content)
            state.dialogVisible = true
            ElMessage({
              type: 'success',
              message: res.data?.msg
            })
          } else {
            ElMessage({
              type: 'error',
              message: res.data?.msg
            })
          }
        })
      },
      changetime: (value: string) => {
        if (value !== null) {
          state.role.datetime = parseTime(value)
        }
      }
    })
    const logoPicRemove = () => { // 清空内容
    //   frontUpload.value.clearFiles()
      imageFrontUrl.value = ''
    }
    const imageUpload = async(file: any) => {
      console.log(file)
      state.role.coverimg = file
    }
    const imgSaveToUrl = (file: any) => {
      imageFrontUrl.value = URL.createObjectURL(file.raw)
      const fileSize = file.size
      const fileType = file.raw.type
      if (!fileSize) {
      // 是否为空
        ElMessage({
          type: 'error',
          showClose: true,
          message: '无效的文件，请重新选择！'
        })
        return
      }
      if (fileSize / 1024 / 1024 > 10) {
      // 图片大小
        ElMessage({
          type: 'error',
          showClose: true,
          message: '请上传小于10M的图片！'
        })
        logoPicRemove()
        return
      }
      if (fileType.indexOf('image') === -1) {
      // 如果不是图片格式
        ElMessage({
          type: 'error',
          showClose: true,
          message: '不是有效的图片文件，或格式不支持，请重新选择!'
        })
        logoPicRemove()
        return
      }
      imageUpload(file)
    }
    const handleSuccess = (res: any) => {
      if (res.code === 0) {
        setTimeout(() => {
          ElMessage({
            type: 'success',
            message: '添加成功'
          })
          state.dialogVisible = false
          state.role = {
            title: '',
            content: '',
            introduce: '',
            datetime: '',
            coverimg: ''
          }
          state.defaultFileList = []
        }, 1000)
      } else {
        setTimeout(() => {
          ElMessage({
            type: 'success',
            message: res?.msg
          })
          state.dialogVisible = false
        }, 1000)
      }
    }
    onBeforeMount(() => {
    //   getServiceRoutes()
      state.getAllUserNo()
    })
    return {
      ...toRefs(state),
      imgSaveToUrl,
      handleSuccess
    }
  }
})
